import React from "react";

const HpLogo = () => {
  return (
    <div style={{ marginRight: "10px" }}>
      <svg
        width="32"
        height="32"
        enable-background="new 0 0 800 800"
        version="1.0"
        viewBox="0 0 1e3 999.98"
        xmlSpace="preserve"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="translate(-203.54 -212.7)">
          <path
            d="m704.93 212.7-107.58 301.62 73.131 0.0229c52.68 0.0153 75.098 41.551 58.509 88.066l-106.97 299.91h-107.64l117.69-330h-55.422l-117.68 330h-108.57l241.48-677.11c-222.38 50.731-388.34 249.71-388.34 487.46 0 234.05 160.82 430.51 377.98 484.98l243.97-685.01h176.69c36.545 0 82.508 24.641 62.646 80.724l-99.203 280.11c-12.672 35.78-42.793 43.031-66.789 43.031h-108.62l-105.35 295.79c6.2031 0.2273 12.418 0.3834 18.672 0.3834 276.14 0 500-223.85 500-500-7e-4 -275.68-223.11-499.23-498.62-499.98zm208.15 358.81-102.47 287.64h54.567l102.49-287.64z"
            fill="#0278ab"
          />
        </g>
      </svg>
    </div>
  );
};

export default HpLogo;
