import styled from "styled-components";
import { typography } from "@veneer/tokens/dist/tokens";
import { getFontFamily } from "../../utils/commonMethods";
import { Colors } from "../../static/consts";

export const Pagination = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 7px;
  font-family: ${props => getFontFamily(props.locale)};
`;
export const Disclaimer = styled.div`
  position: absolute;
  bottom: 24px;
  left: 30px;
  right: 24px;
  font-size: 7px;
  line-height: 10px;
  font-family: ${props => getFontFamily(props.locale)};
`;

export const PDFContainer = styled.div`
  padding: 50px 24px 70px 24px;
  font-size: 12px;
  line-height: 1;
  font-family: ${props => getFontFamily(props.locale)};
`;

export const BannerContainer = styled.div`
  position: absolute;
  top: 24px;
  left: 24px;
  display: flex;
  padding-bottom: 12px;
`;


export const BannerText = styled.span`
  padding-top: 5px;
  font-size: 12px;
  width: 90%;
`;

export const BannerTextContainer = styled.div`
  display: flex;
  width: 480px;
`;

export const SubContent = styled.span`
  padding-top: 10px;
  //font-family: ${typography.family0};
  font-size: 8px;
  line-height: 10px;
`;
export const RiskIndicatorContainer = styled.div`
  display: flex;
  padding: 40px 10px 10px 10px;
  font-size: 10px;
  justify-content: space-between;
  margin-top: 20px;
`;
export const DeviceName = styled.div`
  font-size: 12px;
  // font-family: ${typography.family0};
`;
export const RiskIndicators = styled.div`
  display: flex;
  // font-family: ${typography.family0};
`;

export const ColoredDot = styled.span`
  height: 7px;
  width: 7px;
  border-radius: 50%;
  display: inline-flex;
  margin-left: 6px;
  margin-right: 6px;
  background-color: ${props => props.color};
`;
function getRiskColor(risk) {
  switch (risk) {
    case "passed":
      return Colors.riskIndicationColors.passed;
    case "low":
      return Colors.riskIndicationColors.low;
    case "medium":
      return Colors.riskIndicationColors.medium;
    case "high":
      return Colors.riskIndicationColors.high;
    case "notassessed":
      return Colors.purple6;
    case "unknown":
      return Colors.gray4;
    default:
      return Colors.gray4;
  }
}
export const RiskColor = styled(ColoredDot)`
  background-color: ${props => getRiskColor(props.risk)};
  margin-top: 1px;
`;

export const Table = styled.table`
  font-size: 10px;
  border: 0.7px solid ${Colors.gray1};
  margin-bottom: 3px;
  width: 100%;
`;

export const THead = styled.div`
  // font-family: ${typography.family0};
  background-color: rgba(58, 180, 235, 0.05);
  border-bottom: 0.7px solid ${Colors.gray1};
  font-size: 10px;
  padding: 3px;
  width: 557px;
  display: flex;
`;

export const HData = styled.div`
  padding: 10px 8px;
  white-space: nowrap;
  width: 50%;
  word-wrap: break-word;
`;
export const BoldFont = styled.span`
  color: ${Colors.black};
`;

export const BoldSubText = styled.span`
  color: ${Colors.gray6};
`;
export const AssessmentResult = styled.td`
  // font-family: ${typography.family0};
  color: ${Colors.gray6};
  padding-left: 50px;
  flex-wrap: wrap;
`;
export const RemediationResult = styled.td`
  // font-family: ${typography.family0};
  color: ${Colors.gray6};
  padding-left: 50px;
  width: 300px;
  flex-wrap: wrap;
`;
export const ValueMismatchContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  word-wrap: break-word;
`;
export const SettingNameHeading = styled.td`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 20px;
  max-width: 250px;
  padding-top: 10px;
`;
export const SettingName = styled.p`
  color: ${Colors.black};
  // font-family: ${typography.family0};
  width: 90%;
`;
export const SubSettingName = styled.td`
  color: ${Colors.gray6};
  padding-top: 5px;
  padding-left: 20px;
  width: 30%;
  max-width: 250px;
`;
