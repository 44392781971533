import styled from "styled-components";

export const Remediate = styled.div`
  color: #212121;
  font-weight: 600;
`;
export const ReportLeftRight = styled.div`
  margin-left: 32px !important;
  width: 50%;
`;
export const ReportLeft = styled.div`
   display: flex;
  justify-content: flex-start;
  width: 40%;
  color: #212121 !important;
  line-height: 20px;
  margin-bottom: 8px;
  padding-left: ${props => props.paddingLeft};
`;

export const ReportLeftSubItem = styled.div`
  width: 40%;
  color: #212121 !important;
  line-height: 20px;
  margin-bottom: 8px;
  padding-left: ${props => props.paddingLeft};
`;

export const ReportRightCenter = styled.div`
  width: 30%;
  text-align: left;
  line-height: 24px;
  padding-left: ${props => props.paddingLeft};
`;
export const ReportRightRight = styled.div`
  width: 30%;
  // font-family: "HP Simplified", "HP Simplified ME", Arial, sans-serif;
`;
export const AssessmentContainer = styled.div`
  width: 100%;
  display: inline-flex;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 8px;
  color: #212121;
`;

export const ReportRiskColor = styled.span`
  height: 16px;
  width: 16px;
  border-radius: 50%;
  display: inline-block;
  background-color: ${props => props.backgroundColor};
`;
