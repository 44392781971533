/* eslint-disable prettier/prettier */
import { JarvisWebHttpClient } from "@jarvis/web-http";
import { sprintf } from "sprintf-js";
import { getProps } from "../utils/commonMethods";

export class DeviceAssesmentClient {
  baseUrl;
  deviceId;
  days;
  apiversion = "2";
  jarvisWebClient;

  constructor(baseUrl, deviceId, when) {
    const props = getProps();
    this.baseUrl = baseUrl;
    this.deviceId = deviceId;
    this.days = when;
    this.jarvisWebClient = new JarvisWebHttpClient({
      defaultBaseURL: this.baseUrl,
      defaultAuthProvider: props.authProvider
    });
  }
  isStatusCodeOk(status) {
    return status > 199 && status < 400;
  }

  async getAssessmentValue() {
    const response = await this.jarvisWebClient.get({
      url: this.securityDevicesEndPoint()
    });
    if (this.isStatusCodeOk(response.status)) {
      if (response.data) {
        {
          return response.data;
        }
      }
      return [];
    }
    throw response.statusText;
  }

  securityDevicesEndPoint() {
    return sprintf(
      "%s/v%s/devices/%s/securityAssessmentDetails?since=%s",
      this.baseUrl,
      this.apiversion,
      this.deviceId,
      this.days
    );
  }
}
