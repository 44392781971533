export const service = {
  collection: "collection",
  collectionStratusPie: "collectionStratusPie",
  deviceCache: "deviceCache"
};

export const DeviceMenuBreadcrumbData = t => {
  return {
    key: "menu-devices",
    text:
      "HP Secure Fleet Manager:" +
      t("ecp-endpointsecurity-devicespage.breadCrumb.devices", "Devices"),
    url: "/solutions/security/devices"
  };
};

export const DeviceDetailsBreadcrumbData = t => {
  return {
    key: "deviceDetails",
    text: t("ecp-endpointsecurity-devicespage.breadCrumb.details", "Details")
  };
};
