import { ProgressIndicator } from "@veneer/core";
import styled from "styled-components";
import { getCompliantStatusColor } from "../../static/consts";
import { ColorDot } from "../DeviceDetailsCard/style";

export const PolicyCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 0px;
  gap: 8px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  border-bottom: 1px solid rgba(33, 33, 33, 0.1);
  &:last-child {
    border: 0px;
  }
`;

export const PolicyInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: none;
  order: 0;
  flex-grow: 1;
`;

export const PolicyStatus = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 8px;
  flex: none;
  order: 1;
  flex-grow: 0;
  // min-width: 250px;
`;

export const PolicyComplianceText = styled.div`
  // font-family: HP Simplified;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #212121;
`;

export const PolicyName = styled.span`
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  // font-family: HP Simplified;
  color: #212121;
`;

export const PolicyLastRun = styled.span`
  // font-family: HP Simplified Light;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #404040;
`;

export const PolicyStatusInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 0px;
  gap: 5px;
  flex: none;
  flex-grow: 0;
`;

export const PolicyStatusColor = styled(ColorDot)`
  background-color: ${props => getCompliantStatusColor(props.status)};
`;

export const Loader = styled(ProgressIndicator)`
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 50px;
`;
