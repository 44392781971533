import React, { useState, useEffect } from "react";
import { Accordion } from "@veneer/core";
import {
  Button,
  Card,
  IconChevronRight,
  IconWarningAlt,
  ThemeProvider
} from "@veneer/core";
import { DeviceDetailsheading } from "../DeviceDetailsProfile/style";
import {
  Loader,
  PolicyCard,
  PolicyInfo,
  PolicyStatus,
  PolicyName,
  PolicyLastRun,
  PolicyStatusInfo
  // PolicyStatusColor,
  // PolicyComplianceText
} from "./style";
import { useI18n } from "@jarvis/react-portal-addons";
import { getDateAndTimeFormat4 } from "../../utils/commonMethods";
import getPolicyDetails from "../../hooks/getPolicyDetails";
import {
  DeviceNotExist,
  WarningIcon,
  WarningLabel
} from "../DeviceDetailsCard/style";

interface policyInterface {
  name: string;
  lastAssessed: Date;
  policyId: string;
}

const DevicePolicies = props => {
  const { devicePolicies, reportSectionLoading, deviceId, locale } = props;
  const [devicePolicyDetails, setDevicePolicyDetails] = useState([]);
  const [policyLoader, setPolicyLoader] = useState(false);
  const [expanded, setExpanded] = useState(true);

  const { fetchPolicyName } = getPolicyDetails();

  const { t } = useI18n();
  const handleExpand = () => {
    setExpanded(true);
  };
  const handleCollapse = () => {
    setExpanded(false);
  };

  useEffect(() => {
    getPolicyNames();
  }, [devicePolicies]);

  const getPolicy = policy => {
    if (policy?.policyId !== "devicePolicy") {
      if (policy?.name) {
        if (policy?.name?.length < 35) {
          return policy?.name;
        } else {
          return policy?.name.substring(0, 35) + "...";
        }
      }
    }
    return t(
      "ecp-endpointsecurity-devicespage.devicePolicies.devicePolicyName",
      "Device-Specific Policy"
    );
  };

  const getPolicyNames = async () => {
    if (devicePolicies && devicePolicies.length > 0) {
      setPolicyLoader(true);
      try {
        const data = devicePolicies.map(async policy => {
          if (policy?.policyId) {
            const res = await fetchPolicyName(policy?.policyId, props?.stack);
            return { ...policy, name: res?.name };
          }
        });
        const updatedPolicyDetails = await Promise.all(data);
        setDevicePolicyDetails(
          updatedPolicyDetails.sort(
            (a: policyInterface, b: policyInterface) =>
              new Date(b.lastAssessed).valueOf() -
              new Date(a.lastAssessed).valueOf()
          )
        );
      } catch (err) {
        console.log(err);
      }
      setPolicyLoader(false);
    }
  };

  const ProfileDetails = [
    {
      id: "device-policies",
      expanded,
      header: {
        centralArea: (
          <DeviceDetailsheading data-testid="device-policies-heading">
            {t(
              "ecp-endpointsecurity-devicespage.devicePolicies.heading",
              "Policies"
            )}
          </DeviceDetailsheading>
        )
      },
      content: (
        <>
          {policyLoader || reportSectionLoading ? (
            <Loader></Loader>
          ) : (
            <>
              {devicePolicyDetails && devicePolicyDetails.length > 0 ? (
                devicePolicyDetails.map(policy => (
                  <PolicyCard key={policy?.policyId}>
                    <PolicyInfo>
                      <PolicyName>{getPolicy(policy)}</PolicyName>
                      <PolicyLastRun>
                        {`${t(
                          "ecp-endpointsecurity-devicespage.devicePolicies.lastRunOn",
                          "Last run on"
                        )} ${getDateAndTimeFormat4(
                          policy?.lastAssessed,
                          locale
                        )}`}
                      </PolicyLastRun>
                    </PolicyInfo>
                    <PolicyStatus>
                      <PolicyStatusInfo>
                        {/* <PolicyStatusColor status={devicePolicies?.result} />
                <PolicyComplianceText>
                  {/* {t(`ecp-endpointsecurity-devicespage.common.risks.` + props.deviceDetails.assessmentStatus, "")}
                  {devicePolicies?.result}
                </PolicyComplianceText> */}
                      </PolicyStatusInfo>
                      <ThemeProvider density="high">
                        <Button
                          style={{ minWidth: "137px" }}
                          appearance="ghost"
                          trailingIcon={<IconChevronRight size={24} />}
                          onClick={() =>
                            policy?.policyId !== "devicePolicy"
                              ? props.navigation.push(
                                  "/policies/" + policy?.policyId
                                )
                              : props.navigation.push(
                                  "/devices/" + deviceId + "/policy"
                                )
                          }
                        >
                          {t(
                            "ecp-endpointsecurity-devicespage.devicePolicies.viewDetails",
                            "View Details"
                          )}
                        </Button>
                      </ThemeProvider>
                    </PolicyStatus>
                  </PolicyCard>
                ))
              ) : (
                <DeviceNotExist>
                  <WarningIcon data-testid="warningIcon">
                    <IconWarningAlt size={24} />
                  </WarningIcon>
                  <WarningLabel data-testid="warningLabel">
                    {t(
                      `ecp-endpointsecurity-devicespage.devicePolicies.noDataAvailable`,
                      "No data available"
                    )}
                  </WarningLabel>
                </DeviceNotExist>
              )}
            </>
          )}
        </>
      )
    }
  ];

  return (
    <Card
      data-testid="DevicePolicies-accordian-card"
      appearance="dropShadow"
      content={
        (
          <Accordion
            data-testid="DevicePolicies-accordian"
            key="accordion"
            items={ProfileDetails}
            onExpand={handleExpand}
            onCollapse={handleCollapse}
          />
        ) as any
      }
    />
  );
};
export default DevicePolicies;
