import { isMockUpMode, getMockUpData } from "../../utils/commonMethods";

const useGetCollectionByEntityId = async (client, deviceId, options) => {
  let response, error;

  if (isMockUpMode()) {
    await getMockUpData("collectionByEntityReturn").then(data => {
      response = data.contents;
      console.log("response", response);
    });
    return { response, error };
  }

  await client
    .getCollectionByEntityId(
      "devices",
      deviceId,
      options.offset,
      options.limit,
      options.sortBy
    )
    .then(res => {
      response = res.data.contents;
    })
    .catch(err => {
      error = err;
    });

  return { response, error };
};

const useGetCollections = async (client, offset, limit, name?) => {
  let response, error;

  if (isMockUpMode()) {
    await getMockUpData("deviceGroups").then(data => {
      response = data;
    });
    return { response, error };
  }

  await client
    .getCollections(offset, limit, undefined, name ?? undefined)
    .then(res => {
      response = res.data;
    })
    .catch(err => {
      error = err;
    });
  return { response, error };
};

export const collectionSvc = {
  useGetCollectionByEntityId,
  useGetCollections
};
