import styled from "styled-components";
export const ReportArea = styled.div`
  padding: 0px 12px;
`;
export const ReportHeader = styled.div`
  padding: 0px 0px 16px 8px;
`;
export const ReportSubHeader = styled.div`
  padding: 12px 12px;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
`;
export const DeviceNameAssess = styled.h6`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 20px;
  max-width: 400px;
  line-height: 24px;
  display: inline-block;
  word-wrap: normal;
`;

export const ReportRiskColorIndicator = styled.span`
  height: 16px;
  width: 16px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 12px;
  margin-left: 20px;
  background-color: ${props => props.backgroundColor};
`;

export const ReportRiskColor = styled.div`
  height: 16px;
  width: 16px;
  border-radius: 50%;
  display: inline-block;
`;

export const ReportRiskLabel = styled.div`
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  justify-content: space-between;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #212121;
`;

export const Consolidated = styled.p`
  font-size: 16px !important;
  line-height: 24px;
`;
export const LastSynced = styled.p`
  font-size: 14px !important;
  line-height: 20px;
  color: #737373;
  white-space: pre;
`;
export const ReportHeaderText = styled.h4`
  font-size: 18px;
  padding-bottom: 8px;
  line-height: 24px;
`;
export const AssessConsolidated = styled.p`
  font-size: 14px !important;
  line-height: 20px;
  padding-bottom: 6px;
  color: #212121;
`;
export const LastRunLabel = styled.p`
  font-size: 14px !important;
  line-height: 24px;
  color: #737373;
  white-space: pre;
`;
export const LastRunDate = styled.span`
  padding-left: 80px;
  font-size: 16px;
  color: #212121;
`;
