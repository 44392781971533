/* eslint-disable camelcase */

import nl_NL from "./nl_NL.json";
import sv_SE from "./sv_SE.json";
import en_US from "./en_US.json";
import fr_FR from "./fr_FR.json";
import it_IT from "./it_IT.json";
import de_DE from "./de_DE.json";
import es_ES from "./es_ES.json";
import ja_JP from "./ja_JP.json";
import ko_KR from "./ko_KR.json";
import pt_PT from "./pt_PT.json";
import zh_TW from "./zh_TW.json";
import pl_PL from "./pl_PL.json";
import ru_RU from "./ru_RU.json";
import zh_CN from "./zh_CN.json";
import da_DK from "./da_DK.json";

export default {
  nl_NL,
  sv_SE,
  en_US,
  fr_FR,
  it_IT,
  de_DE,
  es_ES,
  ja_JP,
  ko_KR,
  pt_PT,
  zh_TW,
  pl_PL,
  ru_RU,
  zh_CN,
  da_DK
};
