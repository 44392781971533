import PropTypes from "prop-types";
import React from "react";
import { AssessmentContainer, ReportLeft, ReportRiskColor } from "./style";
import { Colors } from "../../static/consts";
import { useI18n } from "@jarvis/react-portal-addons";
import { showSubSetting, retainJobTimes } from "../../static/consts";

const AccordionContentRemediation = ({ remediationSettings, isWex }) => {
  const { t } = useI18n();

  const getValue = (value, policyItem = "") => {
    if (Array.isArray(value)) return "";
    switch (value) {
      case "":
      case null:
      case "NOT_SET":
      case "not set":
        return t(
          "ecp-endpointsecurity-devicespage.report.settingValues.notSet",
          "Not Set"
        );
      case "SET":
      case "set":
        return t(
          "ecp-endpointsecurity-devicespage.report.settingValues.set",
          "Set"
        );
      case "true":
        return t(
          "ecp-endpointsecurity-devicespage.report.settingValues.enabled",
          "Enabled"
        );
      case "false":
        return t(
          "ecp-endpointsecurity-devicespage.report.settingValues.disabled",
          "Disabled"
        );
    }
    if (policyItem === "check-latest.check") {
      return value;
    }
    const array = value.split(".");

    if (
      (retainJobTimes.includes(value) || array.length === 3) &&
      !t(
        "ecp-endpointsecurity-devicespage.report.settingValues." + value,
        ""
      ).includes(
        "ecp-endpointsecurity-devicespage.report.device.report.settingValues."
      )
    )
      return t(
        "ecp-endpointsecurity-devicespage.report.settingValues." + value,
        ""
      );

    if (policyItem === "web-encryption.ciphers")
      return JSON.parse(value)
        .map(item =>
          t("ecp-endpointsecurity-devicespage.report.settingValues." + item, "")
        )
        .join();

    return array[array.length - 1];
  };
  const leftArea = compliant => {
    switch (compliant) {
      case "passed":
        return (
          <ReportRiskColor backgroundColor={Colors.riskIndicationColors.passed}></ReportRiskColor>
        );

      case "low":
        return (
          <ReportRiskColor backgroundColor={Colors.riskIndicationColors.low}></ReportRiskColor>
        );

      case "medium":
        return (
          <ReportRiskColor backgroundColor={Colors.riskIndicationColors.medium}></ReportRiskColor>
        );

      case "high":
        return <ReportRiskColor backgroundColor={Colors.riskIndicationColors.high}></ReportRiskColor>;

      case "unknown":
        return <ReportRiskColor backgroundColor={Colors.riskIndicationColors.unknown}></ReportRiskColor>;

      default:
        break;
    }
  };

  const showSubSettings = setting => {
    if (!showSubSetting[setting.settingId]) {
      if (
        setting.policyItemsAssessment[0].assessmentObject.assessmentResult ===
        "remediateSucceeded"
      ) {
        return (
          t("ecp-endpointsecurity-devicespage.report.newValue", "New Value: ") +
          getValue(
            setting.policyItemsAssessment[0].assessmentObject.policyValue,
            setting.policyItemsAssessment[0].policyItemId
          ) +
          ", " +
          t("ecp-endpointsecurity-devicespage.report.oldValue", "Old value: ") +
          getValue(
            setting.policyItemsAssessment[0].assessmentObject.deviceValue,
            setting.policyItemsAssessment[0].policyItemId
          )
        );
      } else {
        return t(
          "ecp-endpointsecurity-devicespage.report.assessmentResultReason.deviceRejectedValue",
          "Remediation Failed"
        );
      }
    }
    return "";
  };
  return (
    <div>
      {remediationSettings[0].map(setting => {
        return (
          <>
            <AssessmentContainer
              data-testid={"device-report-assessment-container"}
            >
              <ReportLeft data-testid={"device-report-policyitem-settingItem"}>
                {leftArea(
                  setting.complianceResult ? setting.complianceResult : "high"
                )}
                &nbsp;&nbsp;&nbsp;
                <strong>
                  {t(
                    `ecp-endpointsecurity-devicespage.report.settings.${setting.settingId}.name`,
                    ""
                  )}
                </strong>
              </ReportLeft>
              <div
                style={{
                  paddingLeft: "30px",
                  display: "flex",
                  flexWrap: "wrap"
                }}
              >
                {showSubSettings(setting)}
              </div>
            </AssessmentContainer>
            {showSubSetting[setting.settingId]
              ? setting.policyItemsAssessment.map((policyItem, index) => {
                  return (
                    <AssessmentContainer
                      data-testid={"device-report-assessment-container"}
                      key={`device-report-rem-container-${policyItem.policyItemId}`}
                    >
                      <ReportLeft
                        paddingLeft="30px"
                        data-testid={"device-report-policyitem-settingItem"}
                      >
                        <p>
                          {" "}
                          {t(
                            `ecp-endpointsecurity-devicespage.report.settings.${policyItem.policyItemId}`,
                            ""
                          )}
                        </p>
                      </ReportLeft>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          maxWidth: "50%",
                          paddingLeft: isWex ? "30px" : "0"
                        }}
                      >
                        {policyItem.assessmentObject.assessmentResult ===
                        "remediateSucceeded" ? (
                          <>
                            <span>
                              {t(
                                "ecp-endpointsecurity-devicespage.report.newValue",
                                "New Value:"
                              )}{" "}
                              <span>
                                {getValue(
                                  policyItem.assessmentObject.policyValue,
                                  policyItem.policyItemId
                                ) + ", "}
                              </span>
                            </span>
                            <span>
                              {t(
                                "ecp-endpointsecurity-devicespage.report.oldValue",
                                "Old value:"
                              )}
                              <span>
                                {getValue(
                                  policyItem.assessmentObject.deviceValue,
                                  policyItem.policyItemId
                                )}
                              </span>
                            </span>
                          </>
                        ) : (
                          t(
                            "ecp-endpointsecurity-devicespage.report.assessmentResultReason.deviceRejectedValue",
                            "Remediation Failed"
                          )
                        )}
                      </div>
                    </AssessmentContainer>
                  );
                })
              : ""}
          </>
        );
      })}
    </div>
  );
};

export default AccordionContentRemediation;

AccordionContentRemediation.propTypes = {
  remediationSettings: PropTypes.array.isRequired
};
