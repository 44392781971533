import { getProps } from "../utils/commonMethods";
import DeviceClient from "../client/DeviceClient";
import { getEnvConfig } from "../static/consts";

const useGetDetails = stack => {
  const props = getProps();
  const config = getEnvConfig(stack);
  const devicesClient = new DeviceClient(
    stack,
    config.deviceApiUrl,
    props.authProvider
  );
  const fetchDevicesAPI = async filterOptions => {
    return await devicesClient.exportDevicesApi(filterOptions).then(devices => {
      return devices;
    });
  };
  const fetchDeviceCountAPI = async () => {
    return await devicesClient.exportDeviceCountApi().then(count => {
      return parseInt(count);
    });
  };
  const fetchDeviceDetailsAPI = async deviceId => {
    return await devicesClient.exportDeviceDetailsApi(deviceId);


  };

  return {
    fetchDevicesAPI,
    fetchDeviceDetailsAPI,
    fetchDeviceCountAPI
  };
};

export default useGetDetails;
