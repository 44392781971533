/* eslint-disable prettier/prettier */
import { JarvisAuthProvider } from "@jarvis/web-http";
// import { Stack } from "@jarvis/web-stratus-client";
import {
  DeviceDetailsBreadcrumbData,
  DeviceMenuBreadcrumbData
} from "./constants";

type ManageDeviceProps = {
  country?: string;
  language?: string;
  authProvider: JarvisAuthProvider;
};

let localprops: ManageDeviceProps = {
  language: "en",
  country: "US",
  authProvider: null
};

export const getProps = (): ManageDeviceProps => {
  return localprops;
};

export const setProps = props => {
  localprops = props;
};

export const getDateAndTime = (date_time?, locale = "en-US") => {
  const today = date_time ? new Date(date_time) : new Date();
  let dateAndTime =
    `${today.getDate().toString().padStart(2, "0")} ${today.toLocaleDateString(
      locale,
      {
        month: "short"
      }
    )} ${today.toLocaleDateString(locale, {
      year: "numeric"
    })}` +
    `  ` +
    `|` +
    `  ` +
    `${today.toLocaleTimeString(locale, {
      hour: "2-digit",
      hour12: true,
      minute: "numeric",
      second: "numeric"
    })}`;
  dateAndTime = dateAndTime.replace(/\n/g, " ");
  return dateAndTime;
};

export const getDateAndTimeFormat2 = (date_time?) => {
  const today = date_time ? new Date(date_time) : new Date();
  const locale: string = "en";
  let dateAndTime =
    `${today.toLocaleDateString(locale, {
      month: "short"
    })} ${today
      .getDate()
      .toString()
      .padStart(2, "0")} ${today.toLocaleDateString(locale, {
      year: "numeric"
    })}` +
    `  ` +
    `${today.toLocaleTimeString(locale, {
      hour: "2-digit",
      hour12: true,
      minute: "numeric",
      second: "numeric"
    })}`;
  dateAndTime = dateAndTime.replace(/\n/g, " ");
  return dateAndTime;
};

export const getDateAndTimeFormat3 = (date_time?, locale = "en-US") => {
  if (date_time) {
    const today = new Date(date_time);
    let dateAndTime = `${today.toLocaleDateString(locale, {
      month: "short"
    })} ${today
      .getDate()
      .toString()
      .padStart(2, "0")}, ${today.toLocaleDateString(locale, {
      year: "numeric"
    })}`;
    dateAndTime = dateAndTime.replace(/\n/g, " ");
    return dateAndTime;
  } else {
    return "--";
  }
};

export const getDateAndTimeFormat4 = (date_time?, locale = "en-US") => {
  const today = date_time ? new Date(date_time) : new Date();
  // const locale: string = locale;
  let dateAndTime =
    `${today.toLocaleDateString(locale, {
      month: "short"
    })} ${today
      .getDate()
      .toString()
      .padStart(2, "0")}, ${today.toLocaleDateString(locale, {
      year: "numeric"
    })}` +
    `  ` +
    `${today.toLocaleTimeString(locale, {
      hour: "2-digit",
      hour12: true,
      minute: "numeric"
    })}`;
  dateAndTime = dateAndTime.replace(/\n/g, " ");
  return dateAndTime;
};

//dd mmm yyyy | hh:MM:ss TT
export const getDateAndTimePDF = (date_time?, locale = "en-US") => {
  const today = date_time ? new Date(date_time) : new Date();
  let time = today.toLocaleTimeString(locale, {
    hour: "2-digit",
    hour12: true,
    minute: "numeric",
    second: "2-digit"
  });
  return `${getDateFormat(date_time, locale)} | ${time}`.replace(/\n/g, " ");
};

//dd mmm yyyy
export const getDateFormat = (date_time, locale) => {
  const today = new Date(date_time);
  const day = today.getDate().toString().padStart(2, "0");
  const month = today.toLocaleDateString(locale, {
    month: "short"
  });
  const year = today.toLocaleDateString(locale, {
    year: "numeric"
  });
  return `${day} ${month} ${year}`.replace(/\n/g, " ");
};
export const toTitleCase = str => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const isMockUpMode = () => {
  if (localStorage.getItem("enable-devices-mockup-data")) {
    return true;
  }
  return process.env.NODE_ENV === "mockup" || process.env.NODE_ENV === "demo";
};

export const getMockUpData = async (moduleName: string) => {
  return (await import(`../../mockup/${moduleName}`)).default;
};

export const getFontFamily = locale => {
  switch (locale) {
    case "zh-CN":
      return "FormaDJRSCDisplay";
    case "zh-TW":
      return "FormaDJRTCDisplay";
    case "ja-JP":
      return "FormaDJRJPDisplay";
    default:
      return "FormaDJRKoreanDisplay";
  }
};

export function changeBreadcrumbs(breadcrumbs, navigation, t) {
  const path = navigation.location.pathname.split("/");
  if (breadcrumbs && breadcrumbs.isEnabled()) {
    breadcrumbs.getBreadcrumbs().forEach(bread => {
      if (path.length == 5) {
        breadcrumbs.remove(bread.key);
        breadcrumbs.add(DeviceMenuBreadcrumbData(t));
        breadcrumbs.add(DeviceDetailsBreadcrumbData(t));
      }
    });
  }
}
