import styled from "styled-components";
import { typography, layout } from "@veneer/tokens/dist/tokens";
import { ProgressIndicator } from "@veneer/core";
import {
  getDeviceStatusColor,
  getAssessmentStatusColor,
  getAdvancedStatusColor,
  Colors
} from "../../static/consts";

import { Accordion } from "@veneer/core";
import { Card } from "@veneer/core";

export const ProgressLoader = styled(ProgressIndicator)`
  padding: 2%;
  width: 61px;
  padding-left: 48%;
`;
export const DeviceNotExist = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  gap: 8px;
  padding: 16px;
`;
export const DeviceNotExistAll = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  gap: 8px;
`;
export const WarningLabel = styled.div`
  font-size: ${typography.size2};
  line-height: ${typography.lineHeight2};
  font-family: ${typography.family0};
`;
export const ErrorLabel = styled.div`
  font-size: ${typography.size2};
  line-height: ${typography.lineHeight2};
  font-family: ${typography.family0};
  padding: ${layout.size2};
  color: ${Colors.HpBlue};
`;
export const WarningIcon = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.gray5};
  background: ${Colors.gray2};
`;
export const DeviceNotExistCardContainer = styled(Card)`
  height: 279px;
  padding: 0px 24px 12px 24px;
`;
export const DetailsCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 7px;
  @media (max-width: 635px) {
    flex-wrap: wrap;
  }
`;

export const DeviceDetailsCardContainer = styled.div`
  width: 980px;
  height: 57px;
`;

export const DetailsCardLeftContent = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  align-items: flex-start;
`;
export const PrinterImgContainer = styled.div`
  width: 144px;
  height: 144px;
  max-width: 144px;
  max-height: 144px;
`;

export const DeviceInfoContainer = styled.span`
  margin-left: 4px;
  margin-bottom: 27px;
  margin-top: 27px;
`;

export const DeviceName = styled.div`
  // font-family: HP Simplified light;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
`;
export const DeviceType = styled.h5`
  background: linear-gradient(180deg, #3ab4eb 0%, #027aae 100%) !important;
  border-radius: ${layout.cornerRadius3} !important;
  color: ${Colors.white} !important;
  border: none !important;
  font-size: ${typography.size2}; !important;
  line-height: ${typography.lineHeight2}; !important;
  font-family: ${typography.family0};
  padding: 6px 16px !important;
  max-width: fit-content;
  margin-top: 16px !important;
  margin-left: 12px;
  margin-bottom: 54px;
`;

export const DetailsCardRightContent = styled.div`
  padding: 0px 0;
  display: flex;
  flex-wrap: nowrap;
  margin: 20px 0px;
  height: 40px;
`;

export const RightContentItemWithoutRightBar = styled.div`
  justify-self: left !important;
  white-space: nowrap;
  min-width: 100px;
  font-size: ${typography.size2};
`;

export const RightContentItem = styled(RightContentItemWithoutRightBar)``;

export const DividerforAssetNumber = styled(RightContentItem)`
  margin-right: 26px;
  margin-left: 8px;
`;

export const DividerforConnectivity = styled(RightContentItem)`
  margin-right: 8px;
  margin-left: 8px;
`;

export const DividerforStatus = styled(RightContentItem)`
  margin-right: 8px;
`;

export const DividerforAssessment = styled(RightContentItem)`
  margin-right: 8px;
  margin-left: 8px;
`;

export const DividerforAdvanced = styled(RightContentItem)`
  margin-right: 8px;
  margin-left: 8px;
`;

export const RightContentItemTitle = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: #737373;
  // font-family: HP Simplified;
`;

export const DeviceStatusContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const RightContentItemData = styled.span`
  display: flex;
  align-items: center;
  // font-family: HP Simplified;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
`;

export const RightContentItemStatusData = styled.div`
  // font-family: HP Simplified;
  font-size: 16px;
  line-height: 20px;
  color: #212121;
  margin-left: 8px;
`;

export const ColorDot = styled.span`
  height: 8px !important;
  width: 8px !important;
  border-radius: 50% !important;
  display: inline-block !important;
  margin-right: 4px !important;
`;

export const DeviceStatus = styled(ColorDot)`
  background-color: ${props => getDeviceStatusColor(props.status)};
`;
export const AssessmentStatus = styled(ColorDot)`
  background-color: ${props => getAssessmentStatusColor(props.status)};
`;
export const AdvancedStatus = styled(ColorDot)`
  background-color: ${props => getAdvancedStatusColor(props.status)};
`;
export const LastPolicyUsedLink = styled.a`
  color: ${Colors.HpBlue};
  line-height: ${typography.lineHeight2};
  font-family: ${typography.family0};
  &:hover {
    border-bottom: 2px solid !important;
    text-decoration: none !important;
    cursor: pointer;
  }
  &:hover::after {
    content: " »";
  }
`;

export const DeviceNameText = styled.p`
  // font-family: HP Simplified;
  font-size: 20px;
  line-height: 28px;
  color: #212121;
  position: static;
  width: 297px;
  left: calc(50% - 297px / 2);
  top: 0%;
  bottom: 0%;
  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const PowerStateContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DeviceGroupDivider = styled.div`
  display: flex;
  align-self: stretch;
  padding: 27px 0px 13px;
  div {
    & {
      display: flex;
      align-items: flex-start;
      padding: 0px 32px;
      transform: rotate(-180deg);
      position: static;
      height: 100%;
    }
    &:after {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      bottom: 0;
      left: 50%;
      border-left: 2px solid rgba(33, 33, 33, 0.1);
      transform: translate(-50%);
    }
    @media (max-width: 645px) {
      display: none;
    }
  }
`;

export const AccordionStyled = styled(Accordion)`
  .vn-animated-height__wrapper-inner > div {
    margin-left: 20px;
    margin-right: 27px;
    padding: 20px 0px;
  }
`;

export const DeviceSupplyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  @media (max-width: 635px) {
    margin-top: 24px;
    width: 100%;
  }
`;

export const DeviceStatusMessage = styled.div`
  width: 100%;
  height: 51px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const Divider = styled.div`
  width: 2px;
  height: 32px;
  background: #ebebeb;
`;
