import { getEnvConfig } from "../static/consts";
import { PolicyClient } from "../client/PolicyClient";

const getPolicyDetails = () => {
  const fetchPolicyName = async (policyId, stack) => {
    try {
      const config = getEnvConfig(stack);
      const policyClient = new PolicyClient(config.PolicyApiUrl, policyId);
      const result = await policyClient.getPolicyName();
      if (result) {
        return result;
      }
      return [];
    } catch (err) {
      console.log(err);

      return [];
    }
  };

  return {
    fetchPolicyName
  };
};

export default getPolicyDetails;
