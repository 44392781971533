import styled from "styled-components";

export const DeviceDetailsProfileAccordion = styled.div`
  border-radius: 11px 12px 0px 0px;
  box-shadow: inset 0px -1px 0px rgba(33, 33, 33, 0.1);
`;

export const DeviceDetailsheading = styled.div`
  // font-family: HP Simplified;
  font-size: 20px;
  line-height: 24px;
  position: static;
  // width: 166px;
  left: calc(50% - 54px / 2);
  top: 0%;
  bottom: 0%;
  color: #212121;
  display: flex;
  align-items: center;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 4px;
`;

export const DeviceProfileHeading = styled.div`
  // font-family: HP Simplified;
  font-size: 12px;
  line-height: 16px;
  color: #404040;
  
`;
export const DeviceProfileCard = styled.div`
  width: 974px;

  left: 0px;
  top: 56px;
  padding: 16px, 24px, 16px, 24px;
  background: #ffffff;
  border-radius: 0px;
  margin: 0px 0px;
`;

export const DeviceProfileContents = styled.div`
  // font-family: HP Simplified;
  font-size: 14px;
  line-height: 20px;
  color: #212121;
  padding: 0px 0px 16px 0px;
`;

export const AccordionCard = styled.div`
  width: 974px;
  height: 172px;
  left: 0px;
  top: 56px;
  padding: 16px, 24px, 16px, 24px;
  background: #ffffff;
  border-radius: 0px;
  margin: 0px 0px;
`;

export const DeviceProfileAdapterheading =  styled.div`
  color: var(--Light-Foreground, #212121);
  font-family: "Forma DJR UI";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  padding: 24px 0px 16px 0px;
`
