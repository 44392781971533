import React from "react";
import {
  AssessmentStatus,
  DetailsCardContainer,
  DetailsCardLeftContent,
  DetailsCardRightContent,
  // TODO: 0.55 DeviceGroupDivider,
  DeviceInfoContainer,
  DeviceName,
  DeviceNotExist,
  DeviceStatus,
  // TODO: 0.55 DeviceStatusContainer,
  DividerforAssessment,
  DividerforConnectivity,
  DividerforAdvanced,
  // TODO: 0.55 DividerforStatus,
  PrinterImgContainer,
  ProgressLoader,
  RightContentItemData,
  RightContentItemTitle,
  // TODO: 0.55 DeviceSupplyContainer,
  // TODO: 0.55 DeviceStatusMessage,
  Divider,
  WarningIcon,
  WarningLabel,
  AdvancedStatus
} from "./style";
import { IconPrinterCheckmark } from "@veneer/core";
import { IconWarningAlt } from "@veneer/core";
import { useI18n } from "@jarvis/react-portal-addons";
// TODO: 0.55 import { PowerState } from "./DeviceStatusIcons";
// TODO: 0.55 import { DeviceSupplyLevels } from "../DeviceDetailsCard/SupplyInformation";

const DeviceDetailsCard = props => {
  const { t } = useI18n();
  //TODO: entitlement
  return props.deviceCardLoading ? (
    <ProgressLoader data-testid="deviceDetailsCard-progressIcon" />
  ) : (
    <>
      {!props.deviceExist ? (
        <DeviceNotExist>
          <WarningIcon data-testid="warningIcon">
            <IconWarningAlt size={24} />
          </WarningIcon>

          <WarningLabel data-testid="warningLabel">
            {t(
              "ecp-endpointsecurity-devicespage.deviceCard.noDevice",
              "Unable to load data"
            )}
          </WarningLabel>
        </DeviceNotExist>
      ) : (
        <DetailsCardContainer data-testid="DetailsCardContainer">
          <div
            style={{ display: "flex", flexDirection: "column", width: "50%" }}
          >
            <DetailsCardLeftContent>
              <PrinterImgContainer>
                {props.deviceDetails.image ? (
                  <img
                    src={props.deviceDetails.image}
                    data-testid="device-img"
                    style={{ maxWidth: "144px", maxHeight: "144px" }}
                  />
                ) : (
                  <IconPrinterCheckmark data-testid="device-img" size={120} />
                )}
              </PrinterImgContainer>
              <DeviceInfoContainer>
                <DeviceName data-testid="device-title">
                  {props?.deviceDetails?.deviceHostname}
                </DeviceName>
                <DeviceName data-testid="device-location">
                  {props?.deviceDetails?.deviceLocation}
                </DeviceName>
              </DeviceInfoContainer>
            </DetailsCardLeftContent>

            <DetailsCardRightContent>
              {/* TODO: 0.55 */}
              {/* <DividerforStatus>
            <RightContentItemTitle>
              {t(`ecp-endpointsecurity-devicespage.deviceCard.deviceStatus`, "Device Status")}
            </RightContentItemTitle>
            <DeviceStatusContainer>
              <PowerState
                state={props?.deviceDetails?.devicePowerState?.toLowerCase()}
                getText={getText}
              />
            </DeviceStatusContainer>
          </DividerforStatus>
          <Divider /> */}

              <DividerforConnectivity>
                <RightContentItemTitle>
                  {t(
                    `ecp-endpointsecurity-devicespage.deviceCard.connectivity`,
                    "CONNECTIVITY"
                  )}
                </RightContentItemTitle>
                {props?.deviceDetails?.connectivity && (
                  <RightContentItemData data-testid="device-connectivity-text">
                    <DeviceStatus status={props?.deviceDetails?.connectivity} />
                    {t(
                      `ecp-endpointsecurity-devicespage.common.connectivity.${props?.deviceDetails?.connectivity}`,
                      ""
                    )}
                  </RightContentItemData>
                )}
              </DividerforConnectivity>
              <Divider />

              <DividerforAssessment>
                <RightContentItemTitle>
                  {t(
                    `ecp-endpointsecurity-devicespage.deviceCard.assessment`,
                    "ASSESSMENT"
                  )}
                </RightContentItemTitle>
                {props?.deviceDetails?.assessmentStatus && (
                  <>
                    <RightContentItemData data-testid="device-security-text">
                      <AssessmentStatus
                        status={props.deviceDetails.assessmentStatus}
                      />
                      {t(
                        `ecp-endpointsecurity-devicespage.common.risks.` +
                          props.deviceDetails.assessmentStatus,
                        ""
                      )}
                    </RightContentItemData>
                  </>
                )}
              </DividerforAssessment>
              <>
                <Divider />
                <DividerforAdvanced>
                  <RightContentItemTitle>
                    {t(
                      `ecp-endpointsecurity-devicespage.deviceCard.advanced`,
                      "ADVANCED"
                    )}
                  </RightContentItemTitle>
                  <RightContentItemData data-testid="device-connectivity-text">
                    <AdvancedStatus status={props.deviceDetails.entitled} />
                    {props.deviceDetails.entitled
                      ? t(
                          `ecp-endpointsecurity-devicespage.common.advanced.active`,
                          "Active"
                        )
                      : t(
                          `ecp-endpointsecurity-devicespage.common.advanced.inactive`,
                          "Inactive"
                        )}
                  </RightContentItemData>
                </DividerforAdvanced>
              </>
            </DetailsCardRightContent>
            {/* <DeviceStatusMessage>
          <RightContentItemTitle>
            {t(`ecp-endpointsecurity-devicespage.deviceCard.deviceStatusMessage`, "DEVICE STATUS MESSAGE")}
          </RightContentItemTitle>
          <RightContentItemData>Ready to print</RightContentItemData>
        </DeviceStatusMessage> */}
          </div>
          {/* TODO: 0.55 */}
          {/* <DeviceGroupDivider>
        <div />
      </DeviceGroupDivider> */}
          {/* <DeviceSupplyContainer>
        <DeviceSupplyLevels supplies={props?.supplies} />
      </DeviceSupplyContainer> */}
        </DetailsCardContainer>
      )}
    </>
  );
};
export default DeviceDetailsCard;
