const ASSESSMENT_READ_SCOPE = "ws-hp.com/smcloud/deviceassessment.READ";
const DEVICES_OWNER_SCOPE = "ws-hp.com/devicecache/devices.OWNER";
const POLICY_READ_SCOPE = "ws-hp.com/fleet/policy.READ";
const TASK_READ_SCOPE = "ws-hp.com/fleet/task.READ";
const ASSESSMENT_FULL_SCOPE = "ws-hp.com/smcloud/deviceassessment.FULL";
const REPORTS_OWNER_SCOPE = "ws-hp.com/smcloud/reports.OWNER";

export const DEVICE_DETAIL_SUMMARY = [{ scope: ASSESSMENT_READ_SCOPE }];
export const DEVICE_GENERAL_INFO = [{ scope: DEVICES_OWNER_SCOPE }];
export const DEVICE_POLICY = [
  { scope: POLICY_READ_SCOPE },
  { scope: TASK_READ_SCOPE }
];
export const REPORT_VIEW = [{ scope: ASSESSMENT_FULL_SCOPE }];
export const EXPORT_REPORT = [{ scope: REPORTS_OWNER_SCOPE }];
