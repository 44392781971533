import { service } from '../utils/constants';
import { useRef } from 'react';
import { DeviceCacheApiClient, CollectionClient } from '@jarvis/web-stratus-client';
import { getProps } from "../utils/commonMethods";

const apiController = (stack) => {
  const props = getProps();

  const getApiClient = (serviceName) => {
    let client;

    switch (serviceName) {
      case service.collection:
        client = useRef<CollectionClient>(null);
        client.current = new CollectionClient(stack, props.authProvider);
        break;

      case service.deviceCache:
        client = useRef<DeviceCacheApiClient>(null);
        client.current = new DeviceCacheApiClient(stack, props.authProvider);
    }

    return client.current;
  };

  return {
    getApiClient,
  };
};

export default apiController;
