import React, { useState, useMemo } from 'react';
import { Accordion } from '@veneer/core';
import * as Wrapper from './style';
import { Card } from '@veneer/core';
import { useI18n } from '@jarvis/react-portal-addons';

const DeviceDetailsProfile = (props) => {
  const { t } = useI18n();
  const [expanded, setExpanded] = useState(true);

  const { deviceProfileDetails } = props;
  const handleExpand = () => {
    setExpanded(true);
  };
  const handleCollapse = () => {
    setExpanded(false);
  };
  const ProfileDetais = useMemo(
    () => [
      {
        content: (
          <Wrapper.DeviceProfileCard>
            {Object.keys(deviceProfileDetails).map((item) => {
              if (item === 'deviceInfoAdapters') {
                const adapters = deviceProfileDetails[item];
                const adapterOrder = ['eth0', 'eth1', 'wifi0', 'wifi1'];
                const detailOrder = ['ipv4', 'ipv6', 'macAddress'];
                if (adapters) {
                  const sortedAdapters = Object.entries(adapters).sort(([name1], [name2]) => {
                    return adapterOrder.indexOf(name1) - adapterOrder.indexOf(name2);
                  });
                  return sortedAdapters.map(([adapterName, adapterDetails]) => (
                    <>
                      <Wrapper.DeviceProfileAdapterheading>
                        {t(
                          `ecp-endpointsecurity-devicespage.deviceGeneralInfo.deviceInfoAdapters.deviceInfoNetworkName.${adapterName}`,
                          ''
                        )}
                      </Wrapper.DeviceProfileAdapterheading>
                      {Object.entries(adapterDetails)
                        .sort(([key1], [key2]) => {
                          return detailOrder.indexOf(key1) - detailOrder.indexOf(key2);
                        })
                        .map(([key, value]) => {
                          if (key === 'macAddress') {
                            value = value.replace(/:/g, '').toUpperCase();
                          }
                          return (
                            <>
                              <Wrapper.DeviceProfileHeading>
                                {t(
                                  `ecp-endpointsecurity-devicespage.deviceGeneralInfo.deviceInfoAdapters.deviceInfoNetworkDetails.${key}`,
                                  key
                                )}
                              </Wrapper.DeviceProfileHeading>
                              <Wrapper.DeviceProfileContents>
                                {value ?? '-'}
                              </Wrapper.DeviceProfileContents>
                            </>
                          );
                        })}
                    </>
                  ));
                }
                return <></>;
              }
              return (
                <>
                  <Wrapper.DeviceProfileHeading>
                    {t(`ecp-endpointsecurity-devicespage.deviceGeneralInfo.${item}`, '')}
                  </Wrapper.DeviceProfileHeading>
                  <Wrapper.DeviceProfileContents>
                    {deviceProfileDetails[item]}
                  </Wrapper.DeviceProfileContents>
                </>
              );
            })}
          </Wrapper.DeviceProfileCard>
        ),
        expanded,
        header: {
          centralArea: (
            <Wrapper.DeviceDetailsheading data-testid="Device-profile-heading">
              {t(
                'ecp-endpointsecurity-devicespage.deviceGeneralInfo.heading',
                'General Information'
              )}
            </Wrapper.DeviceDetailsheading>
          )
        },
        id: 'accordionItem1'
      }
    ],
    [expanded]
  );
  return (
    <Card
      data-testid="deviceDetails-card"
      appearance="dropShadow"
      content={
        (
          <Accordion
            data-testid="DeviceDetails-accordian"
            key="accordion"
            items={ProfileDetais}
            onExpand={handleExpand}
            onCollapse={handleCollapse}
          />
        ) as any
      }
    />
  );
};
export default DeviceDetailsProfile;
