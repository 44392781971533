import PropTypes from "prop-types";
import React from "react";
import {
  AssessmentContainer,
  ReportLeft,
  ReportLeftSubItem,
  ReportRiskColor,
  ReportRightCenter,
  ReportRightRight
} from "./style";
import { typography } from "@veneer/tokens/dist/tokens";
import { useI18n } from "@jarvis/react-portal-addons";
import { showSubSetting, retainJobTimes, Colors } from "../../static/consts";

const AccordionContent = ({ assessmentSettings, isWex }) => {
  const { t } = useI18n();

  const getValue = (value, policyItem = "") => {
    if (Array.isArray(value)) return "";
    switch (value) {
      case "":
      case null:
      case "NOT_SET":
      case "not set":
        return t(
          "ecp-endpointsecurity-devicespage.report.settingValues.notSet",
          "Not Set"
        );
      case "SET":
      case "set":
        return t(
          "ecp-endpointsecurity-devicespage.report.settingValues.set",
          "Set"
        );
      case "true":
        return t(
          "ecp-endpointsecurity-devicespage.report.settingValues.enabled",
          "Enabled"
        );
      case "false":
        return t(
          "ecp-endpointsecurity-devicespage.report.settingValues.disabled",
          "Disabled"
        );
    }
    if (policyItem === "check-latest.check") {
      return value;
    }
    const array = value.split(".");

    if (
      (retainJobTimes.includes(value) || array.length === 3) &&
      !t(
        "ecp-endpointsecurity-devicespage.report.settingValues." + value,
        ""
      ).includes("device.report.settingValues.")
    )
      return t(
        "ecp-endpointsecurity-devicespage.report.settingValues." + value,
        ""
      );

    if (policyItem === "web-encryption.ciphers")
      return JSON.parse(value)
        .map(item =>
          t("ecp-endpointsecurity-devicespage.report.settingValues." + item, "")
        )
        .join();

    return array[array.length - 1];
  };
  const leftArea = compliant => {
    switch (compliant) {
      case "passed":
        return (
          <ReportRiskColor
            backgroundColor={Colors.riskIndicationColors.passed}
          ></ReportRiskColor>
        );

      case "low":
        return (
          <ReportRiskColor
            backgroundColor={Colors.riskIndicationColors.low}
          ></ReportRiskColor>
        );

      case "medium":
        return (
          <ReportRiskColor
            backgroundColor={Colors.riskIndicationColors.medium}
          ></ReportRiskColor>
        );

      case "high":
        return (
          <ReportRiskColor
            backgroundColor={Colors.riskIndicationColors.high}
          ></ReportRiskColor>
        );

      case "unknown":
        return (
          <ReportRiskColor
            backgroundColor={Colors.riskIndicationColors.unknown}
          ></ReportRiskColor>
        );

      default:
        break;
    }
  };
  return (
    <div>
      {assessmentSettings[0].map(setting => {
        return (
          <>
            <AssessmentContainer
              data-testid={"device-report-assessment-container"}
            >
              {leftArea(setting.complianceResult)}&nbsp;&nbsp;&nbsp;
              <ReportLeft data-testid={"device-report-policyitem-settingId"}>
                <strong>
                  {t(
                    `ecp-endpointsecurity-devicespage.report.settings.${setting.settingId}.name`,
                    ""
                  )}
                </strong>
              </ReportLeft>
              {!showSubSetting[setting.settingId] &&
                (setting.policyItemsAssessment[0].assessmentObject
                  .assessmentResult === "assessSucceeded" ? (
                  <>
                    <ReportRightCenter paddingLeft={!isWex ? "3px" : "0"}>
                      {t(
                        "ecp-endpointsecurity-devicespage.report.assessmentResult.assessSucceeded",
                        "Passed"
                      )}
                    </ReportRightCenter>
                    <ReportRightRight> </ReportRightRight>
                  </>
                ) : (
                  <>
                    <ReportRightCenter >
                      {t(
                        "ecp-endpointsecurity-devicespage.report.assessmentResultReason." +
                          setting.policyItemsAssessment[0].assessmentObject
                            .assessmentResultReason,
                        ""
                      )}
                    </ReportRightCenter>
                    <ReportRightRight>
                      {setting.policyItemsAssessment[0].assessmentObject
                        .assessmentResultReason === "valueMismatch" ? (
                        <span
                          style={{
                            display: "flex",
                            flexWrap: "wrap"
                          }}
                        >
                          <span
                            style={{
                              paddingRight: "5px"
                            }}
                          >
                            {t(
                              "ecp-endpointsecurity-devicespage.report.policyValue",
                              "Policy: "
                            )}
                            <span style={{ fontFamily: typography.family0 }}>
                              {getValue(
                                setting.policyItemsAssessment[0]
                                  .assessmentObject.policyValue,
                                setting.policyItemsAssessment[0].policyItemId
                              ) + ", "}
                            </span>
                          </span>
                          <span>
                            {t(
                              "ecp-endpointsecurity-devicespage.report.deviceValue",
                              "Device: "
                            )}
                            <span style={{ fontFamily: typography.family0 }}>
                              {getValue(
                                setting.policyItemsAssessment[0]
                                  .assessmentObject.deviceValue,
                                setting.policyItemsAssessment[0].policyItemId
                              )}
                            </span>
                          </span>
                        </span>
                      ) : (
                        t(
                          "ecp-endpointsecurity-devicespage.report.assessmentResultExtendedReason." +
                            setting.policyItemsAssessment[0].assessmentObject
                              .assessmentResultReason,
                          ""
                        )
                      )}
                    </ReportRightRight>
                  </>
                ))}
            </AssessmentContainer>
            {showSubSetting[setting.settingId] &&
              setting.policyItemsAssessment.map((policyItem, index) => {
                return (
                  <AssessmentContainer
                    data-testid={"device-report-assessment-container"}
                    key={`device-report-assessment-container-${policyItem.policyItemId}`}
                  >
                    <ReportLeftSubItem
                      paddingLeft="30px"
                      data-testid={"device-report-policyitem-settingItem"}
                    >
                      <p>
                        {" "}
                        {t(
                          `ecp-endpointsecurity-devicespage.report.settings.${policyItem.policyItemId}`,
                          ""
                        )}
                      </p>
                    </ReportLeftSubItem>
                    {policyItem.assessmentObject.assessmentResult ===
                    "assessSucceeded" ? (
                      <>
                        <ReportRightCenter paddingLeft= {isWex ? "15px" : "0"}>
                          {t(
                            "ecp-endpointsecurity-devicespage.report.assessmentResult.assessSucceeded",
                            "Passed"
                          )}
                        </ReportRightCenter>
                        <ReportRightRight> </ReportRightRight>
                      </>
                    ) : (
                      <>
                        <ReportRightCenter >
                          {t(
                            "ecp-endpointsecurity-devicespage.report.assessmentResultReason." +
                              policyItem.assessmentObject
                                .assessmentResultReason,
                            ""
                          )}
                        </ReportRightCenter>
                        <ReportRightRight>
                          {policyItem.assessmentObject
                            .assessmentResultReason === "valueMismatch" ? (
                            <span
                              style={{
                                display: "flex",
                                flexWrap: "wrap"
                              }}
                            >
                              <span
                                style={{
                                  paddingRight: "5px"
                                }}
                              >
                                {t(
                                  "ecp-endpointsecurity-devicespage.report.policyValue",
                                  "Policy:"
                                )}
                                <span
                                  style={{ fontFamily: typography.family0 }}
                                >
                                  {getValue(
                                    policyItem.assessmentObject.policyValue,
                                    policyItem.policyItemId
                                  ) + ", "}
                                </span>
                              </span>
                              <span>
                                {t(
                                  "ecp-endpointsecurity-devicespage.report.deviceValue",
                                  "Device:"
                                )}
                                <span
                                  style={{ fontFamily: typography.family0 }}
                                >
                                  {getValue(
                                    policyItem.assessmentObject.deviceValue,
                                    policyItem.policyItemId
                                  )}
                                </span>
                              </span>
                            </span>
                          ) : (
                            t(
                              "ecp-endpointsecurity-devicespage.report.assessmentResultExtendedReason." +
                                policyItem.assessmentObject
                                  .assessmentResultReason,
                              ""
                            )
                          )}
                        </ReportRightRight>
                      </>
                    )}
                  </AssessmentContainer>
                );
              })}
          </>
        );
      })}
    </div>
  );
};

export default AccordionContent;

AccordionContent.propTypes = {
  assessmentSettings: PropTypes.array.isRequired
};
