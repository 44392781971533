import styled from "styled-components";
import { Button } from "@veneer/core";
import { Select } from "@veneer/core";
import { typography } from "@veneer/tokens/dist/tokens";
import { Colors } from "../../static/consts";
import { getFontFamily } from "../../utils/commonMethods";

export const PDFHTMLHolder = styled.div`
  position: absolute;
  left: -2000px;
  max-width: 2000px;
  top: -20000px;
`;

export const ReportSelectorContainer = styled.div`
  display: flex;
  margin: 16px 0px;
`;

export const ReportSelector = styled(Select)`
  margin-right: 12px;
  min-width: 274px;
  max-width: 274px;
`;
export const HorizontalLine = styled.div`
  border-top: 2px solid ${Colors.gray3};
  margin: 8px 0px;
`;

export const SelectTimeDuration = styled.div`
  padding-top: 28px;
  padding-right: 24px;
  height: 36px;
  float: right;
  width: 160px;
`;
export const ReportContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const ReportDetailTitle = styled.div`
  margin-top: 16px;
  height: 40px;
  // font-family: "Forma DJR Micro", Arial, sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  align-items: flex-end;
  display: flex;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px 0;
  span {
    margin-left: 5px;
  }
`;
export const Pagination = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 7px;
  font-family: ${props => getFontFamily(props.locale)};
`;
export const Disclaimer = styled.div`
  position: absolute;
  bottom: 24px;
  left: 30px;
  right: 24px;
  font-size: 7px;
  line-height: 10px;
  font-family: ${props => getFontFamily(props.locale)};
`;

export const ExportButton = styled.div`
  padding-bottom: 20px;
`;
export const Title = styled.p`
  padding-bottom: 16px !important;
  // font-family: "HP Simplified";
  font-size: 32px;
  line-height: 36px;
`;
export const Subtitle = styled.p`
  font-size: 18px !important;
  line-height: 24px !important;
  // font-family: "HP Simplified Light";
  padding-bottom: 16px;
`;
export const ExportTypeSelect = styled.div`
  width: 200px;
`;
export const Footer = styled.div`
  float: right;
  display: flex;
`;
export const CancelButton = styled.div`
  padding-right: 10px;
`;
export const DownloadButton = styled.div`
  @media (max-width: 767px) {
    width: 100% !important;
  }
`;
