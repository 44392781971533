import React, { useMemo } from 'react';
import { InterfacesType } from './types/shell';
import SubMfeECPDevices from '../src/components/subMfeComponents/DeviceList';
import Wex from '../src/components/WEX';

/**
 * @function Root Main function
 * @param props
 * @returns
 */
export default function Root(props) {
  const { v1 } = (window as any).Shell as InterfacesType;
  const namespace = '@jarvis/react-ecp-endpointsecurity-devicespage';
  const { themeProviderProps, userThemeMode } = useMemo(() => {
    return {
      themeProviderProps: v1?.theme?.getThemeProviderProperties?.(),
      userThemeMode: v1?.theme?.getUserThemeMode?.()
    };
  }, [v1?.theme]);

  return (
    <section id={namespace}>
      {props?.isWex ? (
        <Wex {...props} themeProviderProps={themeProviderProps} userThemeMode={userThemeMode} />
      ) : (
        <SubMfeECPDevices {...props} breadCrumbs={v1.breadcrumbs} />
      )}
    </section>
  );
}
