import React from "react";
import { useI18n } from "@jarvis/react-portal-addons";

export const LegalDisclaimer = props => {
  const { t } = useI18n();
  return (
    <p
      style={{
        marginTop: "16px",
        fontSize: "14px",
        lineHeight: "20px",
        padding: "0px 12px"
      }}
    >
      {t(
        `ecp-endpointsecurity-devicespage.report.disclaimer`,
        "This report is provided for general comparison only. The information contained is based on manufacturer's published and internal specifications, and proprietary data and algorithms. The information is not guaranteed accurate by HP Development Company. Users can customize the security policies used in the analysis, which will affect the results. Actual results may vary."
      )}
    </p>
  );
};
