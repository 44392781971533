import { getEnvConfig } from "../static/consts";
import { DeviceAssesmentClient } from "../client/AssesmentClient";

const getAssessmentDetails = () => {
  const fetchDeviceDetailsWithTime = async (deviceId, stack, time) => {
    try {
      const config = getEnvConfig(stack);
      const assessmentClient = new DeviceAssesmentClient(
        config.SSApiUrl,
        deviceId,
        time
      );
      const result = await assessmentClient.getAssessmentValue();
      if (result) {
        return result;
      }
      return [];
    } catch (err) {
      console.log(err);

      return [];
    }
  };


  return {
    // fetchDeviceDetails,
    fetchDeviceDetailsWithTime
  };
};

export default getAssessmentDetails;
