/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { ProgressIndicator } from '@veneer/core';
import { Card } from '@veneer/core';
import { DetailsSelection } from '../DetailsSelection';
import DeviceDetailsCard from '../DeviceDetailsCard';
import getAssessmentDetails from '../../hooks/getAssessmentDetails';
import useGetDetails from '../../hooks/useGetDetailsList';
import DeviceDetailsProfile from '../DeviceDetailsProfile';
import DevicePolicies from '../DevicePolicies';
import {
  DeviceNameText,
  AccordionStyled,
  DeviceNotExistAll,
  WarningIcon,
  WarningLabel,
  DeviceNotExistCardContainer
} from '../DeviceDetailsCard/style';
import { changeBreadcrumbs, getDateAndTimeFormat3, toTitleCase } from '../../utils/commonMethods';
import {
  DEVICE_DETAIL_SUMMARY,
  DEVICE_GENERAL_INFO,
  DEVICE_POLICY,
  EXPORT_REPORT,
  REPORT_VIEW
} from '../../static/scopes';
import { useI18n } from '@jarvis/react-portal-addons';
import { collectionSvc } from '../../api/CollectionSvc';
import apiController from '../../api/apiController';
import { service } from '../../utils/constants';
import { filterOptions } from '../../static/consts';
import { IconWarningAlt } from '@veneer/core';
import data from '../../../local_server/database/database.json';

export const DeviceDetails = (props) => {
  const deviceID = props?.navigation?.location?.pathname?.split('/')[4]
    ? props.navigation.location.pathname.split('/')[4]
    : window.location.pathname.split('/')[6];
  const initDuration = props?.navigation?.location?.pathname?.split('/')[5] ? 3 : 2;

  const [deviceCardLoading, setDeviceCardLoading] = useState(true);
  const [deviceCardDetails, setDeviceCardDetails] = useState({
    deviceName: '',
    policy: ''
  });
  const { isWex, useMockData, deviceId } = props;
  const { t } = useI18n();
  const [deviceProfileDetails, setDeviceProfileDetails] = useState({});
  const [deviceSupplyLevel, setDeviceSupplyLevel] = useState({});
  const [devicePolicies, setDevicePolicies] = useState(null);

  const [reportSectionLoading, setReportSectionLoading] = useState(true);
  const [reportSectionData, setReportSectionData] = useState({});

  const [totalDuration, setTotalDuration] = useState<any>([initDuration]);

  const { fetchDeviceDetailsWithTime } = getAssessmentDetails();
  const { fetchDeviceDetailsAPI } = useGetDetails(props.stack);
  const [firstload, setFirstload] = useState(true);
  const [deviceExist, setDeviceExist] = useState(true);
  const [groups, setGroups] = useState([]);
  const [deviceGroupInfo, setDeviceGroupInfo] = useState<any>([]);
  const { getApiClient } = apiController(props.stack);
  const collectionClient = getApiClient(service.collection);
  const [DEVICE_DETAIL_ACCESS, setDeviceAccess] = useState(false);
  const [DEVICE_GENERAL_INFO_ACCESS, setInfoAccess] = useState(false);
  const [DEVICE_POLICY_ACCESS, setPolicyAccess] = useState(false);
  const [REPORT_VIEW_ACCESS, setReportViewAccess] = useState(false);
  const [EXPORT_REPORT_ACCESS, setExportReportAccess] = useState(false);

  const { accessControl } = props;

  useEffect(() => {
    if (accessControl) {
      // promise order should map to respective set function
      Promise.all([
        accessControl.checkScopes(DEVICE_DETAIL_SUMMARY),
        accessControl.checkScopes(DEVICE_GENERAL_INFO),
        accessControl.checkScopes(DEVICE_POLICY),
        accessControl.checkScopes(REPORT_VIEW),
        accessControl.checkScopes(EXPORT_REPORT)
      ])
        .then((values) => {
          setDeviceAccess(values[0]);
          setInfoAccess(values[1]);
          setPolicyAccess(values[2]);
          setReportViewAccess(values[3]);
          setExportReportAccess(values[4]);
        })
        .catch((err) => console.log(err));
    }
  }, [accessControl]);

  useEffect(() => {
    getDeviceInfo();
  }, []);

  useEffect(() => {
    reloadReportSection();
  }, [totalDuration]);

  const getDeviceInfo = async () => {
    //get device's group info
    const groupInfoByEntity = !isWex
      ? await collectionSvc.useGetCollectionByEntityId(collectionClient, deviceID, {
          ...filterOptions,
          offset: 0,
          limit: -1
        })
      : data.groups;

    let allGroups;
    const allGroupInfo = !isWex
      ? await collectionSvc.useGetCollections(collectionClient, 0, 1, 'All')
      : data.groups;

    allGroups = [{ groupId: allGroupInfo?.response?.contents[0]?.id, groupName: 'All' }];

    if (
      groupInfoByEntity.error === undefined &&
      !(
        groupInfoByEntity?.response &&
        groupInfoByEntity?.response?.length === 1 &&
        groupInfoByEntity?.response[0]?.name === 'Ungrouped'
      )
    ) {
      // If there is group info except 'Ungrouped'
      allGroups = [
        ...allGroups,
        ...groupInfoByEntity.response.map((groupInfo) => {
          return { groupId: groupInfo.id, groupName: groupInfo.name };
        })
      ];
    }
    setGroups(allGroups);
  };

  useEffect(() => {
    let temp_array =
      groups && groups?.length > 0
        ? groups.map((item) => toTitleCase(item.groupName)).join(', ')
        : '--';
    setDeviceGroupInfo(temp_array);
  }, [groups]);

  useEffect(() => {
    if (
      deviceGroupInfo?.length != 0 &&
      deviceGroupInfo != '' &&
      deviceGroupInfo != null &&
      deviceGroupInfo != '--'
    )
      reloadDeviceCard();
  }, [deviceGroupInfo]);

  const reloadDeviceCard = async () => {
    setDeviceCardLoading(true);
    try {
      const details = !isWex ? await fetchDeviceDetailsAPI(deviceID) : data.devices[0];
      setDeviceCardDetails(createDeviceCardData(details));
      setDeviceProfileDetails(createDeviceProfileDetails(details));
      setDeviceSupplyLevel(details?.supplies);
      changeBreadcrumbs(props.breadCrumbs, props.navigation, t);
      setDeviceExist(true);
    } catch (err) {
      if (!isWex) {
        setDeviceExist(false);
      }
      console.log(err);
    } finally {
      setFirstload(false);
      setDeviceCardLoading(false);
    }
  };

  const reloadReportSection = async () => {
    setReportSectionLoading(true);
    let time;
    if (totalDuration[0] === 1) {
      time = '1d';
    } else if (totalDuration[0] === 2) {
      time = '7d';
    } else {
      time = '30d';
    }
    try {
      if (deviceID !== undefined) {
        const response =
          isWex && useMockData
            ? data.assessment[0]
            : await fetchDeviceDetailsWithTime(isWex ? deviceId : deviceID, props.stack, time);

        setReportSectionData(response);
        setDevicePolicies(response?.assessmentStatus?.policyDetails);
        setReportSectionLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [expanded, setExpanded] = useState(true);

  const handleExpand = () => {
    setExpanded(true);
  };
  const handleCollapse = () => {
    setExpanded(false);
  };

  const createDeviceCardData = (details) => {
    return {
      deviceId: details?.deviceId,
      deviceName: details?.identity?.makeAndModel?.name,
      deviceHostname: details?.identity?.friendlyName,
      devicePowerState: details?.status?.powerState,
      connectivity: details?.status?.connectionState,
      assessmentStatus: details?.solutionMetadata?.NudlWuf7yCQ8hPLvryfrT97MUa3q6bWe?.result,
      serialNumber: details?.identity?.serialNumber,
      deviceLocation: details?.identity?.location,
      // assetNumber: details?.identity?.makeAndModel?.number,
      policy: details?.solutionMetadata?.NudlWuf7yCQ8hPLvryfrT97MUa3q6bWe?.securityPolicy,
      policyId: details?.solutionMetadata?.NudlWuf7yCQ8hPLvryfrT97MUa3q6bWe?.policyId,
      group: details.groups == null ? details?.groups?.label : details?.groups[0]?.label,
      image:
        details?.images && details.images.length !== 0
          ? details.images[details.images.length - 1]?.url
          : undefined,
      entitled:
        details?.solutionMetadata?.NudlWuf7yCQ8hPLvryfrT97MUa3q6bWe?.entitlementType === 'premium'
    };
  };

  const createDeviceProfileDetails = (data) => {
    const deviceInfoAdapters = data?.network
      ? data.network.adapters?.reduce((acc, adapter) => {
          if (adapter.type) {
            return {
              ...acc,
              [adapter.name]: {
                macAddress: adapter.macAddress ?? '--',
                ipv4: adapter.ipv4?.address.ip ?? '--',
                ipv6: adapter.ipv6?.address.ip ?? '--'
              }
            };
          }
          return acc;
        }, {})
      : {};

    return {
      deviceInfoAssetNumber: data?.identity?.assetNumber || '--',
      deviceInfoDeviceGroup: deviceGroupInfo,
      deviceInfoDateRegistered: getDateAndTimeFormat3(
        data?.timestamp?.firstRegistrationTime,
        props?.locale
      ),
      deviceInfoFirmwareVersion: data?.identity?.firmwareVersion ?? '--',
      deviceInfoSerialNumber: data?.identity?.serialNumber ?? '--',
      deviceInfoAdapters: deviceInfoAdapters
    };
  };
  return (
    <>
      {firstload ? (
        <Card
          data-testid="ProgressCard"
          background="filled"
          border="dropShadow"
          content={
            (
              <div
                style={{
                  padding: '4%',
                  width: '61px',
                  paddingLeft: '48%'
                }}
              >
                <ProgressIndicator />
              </div>
            ) as any
          }
        ></Card>
      ) : (
        <>
          {' '}
          {deviceExist ? (
            <>
              {!isWex && DEVICE_DETAIL_ACCESS && (
                <Card
                  background="filled"
                  border="dropShadow"
                  content={
                    (
                      <AccordionStyled
                        items={[
                          {
                            content: (
                              <DeviceDetailsCard
                                deviceDetails={deviceCardDetails}
                                deviceCardLoading={deviceCardLoading}
                                navigation={props.navigation}
                                deviceExist={deviceExist}
                                supplyLevelInfo={deviceSupplyLevel}
                              />
                            ),
                            expanded,
                            header: {
                              centralArea: (
                                <DeviceNameText>{deviceCardDetails?.deviceName}</DeviceNameText>
                              )
                            },
                            id: 'accordionItem1'
                          }
                        ]}
                        onExpand={handleExpand}
                        onCollapse={handleCollapse}
                        id="accordion-one-item"
                      />
                    ) as any
                  }
                >
                  <div>{deviceCardLoading}</div>
                  <div>{deviceExist}</div>
                </Card>
              )}
              <div style={{ marginTop: '16px' }}>
                {!isWex && DEVICE_GENERAL_INFO_ACCESS && (
                  <DeviceDetailsProfile deviceProfileDetails={deviceProfileDetails} {...props} />
                )}
              </div>
              {!isWex && (
                <div style={{ marginTop: '16px' }}>
                  {DEVICE_POLICY_ACCESS && (
                    <DevicePolicies
                      deviceId={deviceID}
                      {...props}
                      locale={props?.locale}
                      devicePolicies={devicePolicies}
                      reportSectionLoading={reportSectionLoading}
                    />
                  )}
                </div>
              )}
              {REPORT_VIEW_ACCESS && (
                <DetailsSelection
                  isWex={isWex}
                  deviceName={deviceCardDetails?.deviceName}
                  deviceId={deviceID}
                  reportSectionLoading={reportSectionLoading}
                  setTotalDuration={setTotalDuration}
                  totalDuration={totalDuration}
                  reportSectionData={reportSectionData}
                  navigation={props.navigation}
                  EXPORT_REPORT_ACCESS={EXPORT_REPORT_ACCESS}
                  locale={props?.locale}
                />
              )}
            </>
          ) : (
            <DeviceNotExistCardContainer
              data-testid="device_does_not_exist_card"
              appearance="dropShadow"
              content={
                <DeviceNotExistAll>
                  <WarningIcon data-testid="warningIcon">
                    <IconWarningAlt size={24} />
                  </WarningIcon>

                  <WarningLabel data-testid="warningLabel">
                    {t(
                      'ecp-endpointsecurity-devicespage.deviceCard.deviceDeleted',
                      'This device has been removed'
                    )}
                  </WarningLabel>
                </DeviceNotExistAll>
              }
            />
          )}
        </>
      )}
    </>
  );
};
