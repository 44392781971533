import { Accordion } from "@veneer/core";
import { AccordionItemInterface } from "@veneer/core";
import React, { useState } from "react";
import { useI18n } from "@jarvis/react-portal-addons";
import PropTypes from "prop-types";
import AccordionContent from "../AccordionContent";
import { getDateAndTimeFormat4 } from "../../utils/commonMethods";

const ReportAccordionAssessment = ({
  assessmentDetails,
  selectReportTime,
  locale,
  isWex
}) => {
  const { t } = useI18n();
  const accordionList =
    assessmentDetails && assessmentDetails.length > 0
      ? assessmentDetails.map((assesslist, index) => {
          const arrayOfAssesslist = Object.values(assesslist);
          const item: AccordionItemInterface = {};
          const category = arrayOfAssesslist[0][0]?.category
            ? arrayOfAssesslist[0][0].category
            : "default";
          item.id = "device-report-policyname-" + index;
          item.header = {
            startArea: (
              <span data-testid={"device-report-policyname-" + index}>
                {t(
                  `ecp-endpointsecurity-devicespage.report.activityHeader.left.${category}.part1`,
                  ""
                )}
                {t(
                  `ecp-endpointsecurity-devicespage.report.activityHeader.left.${category}.part2`,
                  ""
                )}
              </span>
            ),
            centralArea: <span></span>,
            endArea: (
              <span data-testid={"device-report-policydaterun-" + index}>
                {t(
                  `ecp-endpointsecurity-devicespage.report.activityHeader.right`,
                  "Date Run: "
                )}
                {getDateAndTimeFormat4(
                  arrayOfAssesslist[0][0]?.assessedTime,
                  locale
                )}
              </span>
            )
          };
          item.expanded = true;
          item.content = (
            <AccordionContent assessmentSettings={arrayOfAssesslist} isWex = {isWex}/>
          );
          return item;
        })
      : [];

  const [data, setData] = useState<AccordionItemInterface[]>(accordionList);
  const handleExpand = (event, index, item) => {
    const updatedData = [...data];
    updatedData[index].expanded = true;
    setData(updatedData);
  };

  const handleCollapse = (event, index, item) => {
    const updatedData = [...data];
    updatedData[index].expanded = false;
    setData(updatedData);
  };

  return (
    <div>
      {assessmentDetails && assessmentDetails.length > 0 ? (
        <Accordion
          gutter={5}
          appearance="outlined"
          items={data}
          onExpand={handleExpand}
          onCollapse={handleCollapse}
          id="AssessmentAccordionReport"
        />
      ) : (
        <div style={{ padding: "7% 0% 7% 38%", fontSize: "19px" }}>
          {t(
            `ecp-endpointsecurity-devicespage.report.placeholder.noDataToShow`,
            "No data to show"
          )}
        </div>
      )}
    </div>
  );
};
export default ReportAccordionAssessment;

ReportAccordionAssessment.propTypes = {
  assessmentDetails: PropTypes.array.isRequired,
  selectReportTime: PropTypes.any.isRequired
};
