import React from "react";
import {
  BannerContainer,
  BannerText,
  BannerTextContainer,
  ColoredDot,
  DeviceName,
  HData,
  PDFContainer,
  RiskColor,
  AssessmentResult,
  RemediationResult,
  RiskIndicatorContainer,
  RiskIndicators,
  SettingName,
  SettingNameHeading,
  SubContent,
  Table,
  THead,
  BoldFont,
  SubSettingName,
  ValueMismatchContainer,
  BoldSubText
} from "./style";
import { getDateAndTimePDF } from "../../utils/commonMethods";

import { typography } from "@veneer/tokens/dist/tokens";
import { Colors } from "../../static/consts";
import { IconWarningAlt } from "@veneer/core";

import {
  showSubSetting,
  retainJobTimes,
  Tablei18nText
} from "../../static/consts";
import {
  getDateAndTime,
  getDateAndTimeFormat4
} from "../../utils/commonMethods";
import HpLogo from "./HpLogo";
const ReportDetailsHTML = (
  type,
  assessment,
  deviceName,
  duration,
  locale,
  t
) => {
  const getValue = (value, policyItem = "") => {
    if (Array.isArray(value)) return "";
    switch (value) {
      case "":
      case null:
      case "NOT_SET":
      case "not set":
        return t(
          "ecp-endpointsecurity-devicespage.report.settingValues.notSet",
          "Not Set"
        );
      case "SET":
      case "set":
        return t(
          "ecp-endpointsecurity-devicespage.report.settingValues.set",
          "Set"
        );
      case "true":
        return t(
          "ecp-endpointsecurity-devicespage.report.settingValues.enabled",
          "Enabled"
        );
      case "false":
        return t(
          "ecp-endpointsecurity-devicespage.report.settingValues.disabled",
          "Disabled"
        );
    }
    if (policyItem === "check-latest.check") {
      return value;
    }
    const array = value.split(".");

    if (
      (retainJobTimes.includes(value) || array.length === 3) &&
      !t(
        "ecp-endpointsecurity-devicespage.report.settingValues." + value,
        ""
      ).includes("device.report.settingValues.")
    )
      return t(
        "ecp-endpointsecurity-devicespage.report.settingValues." + value,
        ""
      );
    if (policyItem === "web-encryption.ciphers")
      return JSON.parse(value)
        .map(item =>
          t("ecp-endpointsecurity-devicespage.report.settingValues." + item, "")
        )
        .join();
    return array[array.length - 1];
  };

  let array = [];
  assessment.settingsAssessmentList.settingsAssessment.forEach(time_group => {
    if (Object.keys(time_group)[0].includes(type)) {
      array.push(Object.values(time_group)[0]);
    }
  });
  const displayNoSubSetting = setting => {
    if (type === "assessment") {
      if (
        setting.policyItemsAssessment[0].assessmentObject.assessmentResult ===
        "assessSucceeded"
      ) {
        return (
          <>
            <AssessmentResult>
              {t(
                "ecp-endpointsecurity-devicespage.report.assessmentResult.assessSucceeded",
                "Passed"
              )}
            </AssessmentResult>
            <td></td>
          </>
        );
      } else {
        return (
          <>
            <AssessmentResult>
              {t(
                "ecp-endpointsecurity-devicespage.report.assessmentResultReason." +
                  setting.policyItemsAssessment[0].assessmentObject
                    .assessmentResultReason,
                ""
              )}
            </AssessmentResult>
            <td>
              {setting.policyItemsAssessment[0].assessmentObject
                .assessmentResultReason === "valueMismatch" ? (
                <ValueMismatchContainer>
                  <span>
                    <BoldFont>
                      {t(
                        "ecp-endpointsecurity-devicespage.report.policyValue",
                        "Policy: "
                      )}
                    </BoldFont>
                    <BoldSubText>
                      {getValue(
                        setting.policyItemsAssessment[0].assessmentObject
                          .policyValue,
                        setting.policyItemsAssessment[0].policyItemId
                      ) + ", "}
                    </BoldSubText>
                  </span>
                  <span>
                    <BoldFont>
                      {t(
                        "ecp-endpointsecurity-devicespage.report.deviceValue",
                        "Device: "
                      )}
                    </BoldFont>
                    <BoldSubText>
                      {getValue(
                        setting.policyItemsAssessment[0].assessmentObject
                          .deviceValue,
                        setting.policyItemsAssessment[0].policyItemId
                      )}
                    </BoldSubText>
                  </span>
                </ValueMismatchContainer>
              ) : (
                <BoldSubText>
                  {t(
                    "ecp-endpointsecurity-devicespage.report.assessmentResultExtendedReason." +
                      setting.policyItemsAssessment[0].assessmentObject
                        .assessmentResultReason,
                    ""
                  )}
                </BoldSubText>
              )}
            </td>
          </>
        );
      }
    } else {
      if (
        setting.policyItemsAssessment[0].assessmentObject.assessmentResult ===
        "remediateSucceeded"
      ) {
        return (
          <>
            <RemediationResult>
              <span>
                <BoldFont>
                  {t(
                    "ecp-endpointsecurity-devicespage.report.newValue",
                    "New Value: "
                  )}
                </BoldFont>
                <BoldSubText>
                  {getValue(
                    setting.policyItemsAssessment[0].assessmentObject
                      .policyValue,
                    setting.policyItemsAssessment[0].policyItemId
                  ) + ", "}
                </BoldSubText>
              </span>
              <span>
                <BoldFont>
                  {t(
                    "ecp-endpointsecurity-devicespage.report.oldValue",
                    "Old Value: "
                  )}
                </BoldFont>
                <BoldSubText>
                  {getValue(
                    setting.policyItemsAssessment[0].assessmentObject
                      .deviceValue,
                    setting.policyItemsAssessment[0].policyItemId
                  )}
                </BoldSubText>
              </span>
            </RemediationResult>
            <td> </td>
          </>
        );
      } else {
        return (
          <>
            <AssessmentResult>
              {t(
                "ecp-endpointsecurity-devicespage.report.assessmentResultReason.deviceRejectedValue",
                "Cannot Remediate"
              )}
            </AssessmentResult>
            <td> </td>
          </>
        );
      }
    }
  };
  const displaySubSetting = setting => {
    if (!showSubSetting[setting.settingId]) return;

    const renderColumn2 = policyItem => {
      const { assessmentResult, assessmentResultReason } =
        policyItem.assessmentObject;

      let content;
      let additionalContent = null;

      if (type === "assessment") {
        content =
          assessmentResult === "assessSucceeded" ? (
            <AssessmentResult>
              {t(
                "ecp-endpointsecurity-devicespage.report.assessmentResult.assessSucceeded",
                "Passed"
              )}
            </AssessmentResult>
          ) : (
            <AssessmentResult>
              {t(
                `ecp-endpointsecurity-devicespage.report.assessmentResultReason.${assessmentResultReason}`,
                ""
              )}
            </AssessmentResult>
          );

        if (assessmentResultReason === "valueMismatch") {
          additionalContent = (
            <ValueMismatchContainer>
              <span>
                <BoldFont>
                  {t(
                    "ecp-endpointsecurity-devicespage.report.policyValue",
                    "Policy: "
                  )}
                </BoldFont>
                <BoldSubText>
                  {getValue(
                    policyItem.assessmentObject.policyValue,
                    policyItem.policyItemId
                  ) + ", "}
                </BoldSubText>
              </span>
              <span>
                <BoldFont>
                  {t(
                    "ecp-endpointsecurity-devicespage.report.deviceValue",
                    "Device: "
                  )}
                </BoldFont>
                <BoldSubText>
                  {getValue(
                    policyItem.assessmentObject.deviceValue,
                    policyItem.policyItemId
                  )}
                </BoldSubText>
              </span>
            </ValueMismatchContainer>
          );
        } else {
          additionalContent = assessmentResult !== "assessSucceeded" && (
            <BoldSubText>
              {t(
                `ecp-endpointsecurity-devicespage.report.assessmentResultExtendedReason.${assessmentResultReason}`,
                ""
              )}
            </BoldSubText>
          );
        }
      } else {
        content =
          assessmentResult === "remediateSucceeded" ? (
            <RemediationResult>
              <span>
                <BoldFont>
                  {t(
                    "ecp-endpointsecurity-devicespage.report.newValue",
                    "New Value: "
                  )}
                </BoldFont>
                <BoldSubText>
                  {getValue(
                    policyItem.assessmentObject.policyValue,
                    policyItem.policyItemId
                  ) + ", "}
                </BoldSubText>
              </span>
              <span>
                <BoldFont>
                  {t(
                    "ecp-endpointsecurity-devicespage.report.oldValue",
                    "Old Value: "
                  )}
                </BoldFont>
                <BoldSubText>
                  {getValue(
                    policyItem.assessmentObject.deviceValue,
                    policyItem.policyItemId
                  )}
                </BoldSubText>
              </span>
            </RemediationResult>
          ) : (
            <RemediationResult>
              {t(
                "ecp-endpointsecurity-devicespage.report.assessmentResultReason.deviceRejectedValue",
                "Remediation Failed"
              )}
            </RemediationResult>
          );
      }

      return (
        <>
          <>{content}</>
          <td>{additionalContent}</td>
        </>
      );
    };

    return setting.policyItemsAssessment.map(policyItem => (
      <tr key={policyItem.policyItemId}>
        <SubSettingName>
          {t(
            `ecp-endpointsecurity-devicespage.report.settings.${policyItem.policyItemId}`,
            ""
          )}
        </SubSettingName>
        {renderColumn2(policyItem)}
      </tr>
    ));
  };
  return (
    <PDFContainer locale={locale}>
      <BannerContainer>
        <HpLogo data-testid="reports_PDF_LogoHp" />
        <BannerText>
          <BannerTextContainer>
            <>
              <BoldSubText style={{ whiteSpace: "no-wrap" }}>
                {t(
                  "ecp-endpointsecurity-devicespage.report.title",
                  "Command Center Security Report: "
                )}
              </BoldSubText>
            </>
            <div style={{ flex: "1" }}>
              <BoldFont style={{ wordWrap: "break-word" }}>
                {t(
                  `ecp-endpointsecurity-devicespage.reportSelection.reportType.${type}`,
                  ""
                )}
              </BoldFont>
            </div>
          </BannerTextContainer>
          <br></br>
          <SubContent>
            <BoldSubText>
              {t(
                `ecp-endpointsecurity-devicespage.report.subtitle.${type}`,
                ""
              )}
            </BoldSubText>
            <br></br>
            <p
              style={{ color: Colors.riskIndicationColors.unknown }}
              data-testid="lastAssessedTime"
            >
              {getDateAndTime(assessment.assessmentStatus.lastAssessed, locale)}
            </p>
            <p>
              <BoldSubText>
                {" "}
                {t(
                  "ecp-endpointsecurity-devicespage.report.dateRange",
                  "Date Run: "
                )}
              </BoldSubText>
              <BoldFont data-testid="AssessedDuration">
                {getDateAndTimePDF(
                  new Date().setDate(new Date().getDate() - duration),
                  locale
                )}{" "}
                to {getDateAndTimePDF(new Date(), locale)}
              </BoldFont>
            </p>
          </SubContent>
        </BannerText>
      </BannerContainer>

      <RiskIndicatorContainer>
        <DeviceName>{deviceName}</DeviceName>
        <RiskIndicators>
          {type === "assessment" ? (
            <p data-testid="PassedIndicator">
              <ColoredDot color={Colors.riskIndicationColors.passed} />
              {t(
                "ecp-endpointsecurity-devicespage.common.risks.passed",
                "Passed"
              )}
            </p>
          ) : (
            <p data-testid="PreviousRiskLegend">
              {t(
                "ecp-endpointsecurity-devicespage.common.previousRisk",
                "Previous Risk:"
              )}
            </p>
          )}
          <p>
            <ColoredDot color={Colors.riskIndicationColors.low} />
            {t(
              "ecp-endpointsecurity-devicespage.common.risks.lowRisk",
              "Low Risk"
            )}
          </p>
          <p>
            <ColoredDot color={Colors.riskIndicationColors.medium} />
            {t(
              "ecp-endpointsecurity-devicespage.common.risks.mediumRisk",
              "Medium Risk"
            )}
          </p>
          <p>
            <ColoredDot color={Colors.riskIndicationColors.high} />
            {t(
              "ecp-endpointsecurity-devicespage.common.risks.highRisk",
              "High Risk"
            )}
          </p>
        </RiskIndicators>
      </RiskIndicatorContainer>

      {array.length > 0 ? (
        array.map((settings, i) => {
          const category = settings[0]?.category
            ? settings[0].category
            : "default";
          return (
            <Table key={category + settings[0].assessedTime}>
              <THead>
                <HData>
                  <BoldSubText>
                    {t(
                      `ecp-endpointsecurity-devicespage.report.activityHeader.left.${category}.part1`,
                      ""
                    )}
                  </BoldSubText>
                  <BoldFont data-testid={"SettingsOnPolicy" + i}>
                    {t(
                      `ecp-endpointsecurity-devicespage.report.activityHeader.left.${category}.part2`,
                      ""
                    )}
                  </BoldFont>
                </HData>

                <HData
                  style={{
                    textAlign: "right",
                    paddingRight: "12px",
                    float: "right"
                  }}
                >
                  <BoldSubText>
                    {t(
                      "ecp-endpointsecurity-devicespage.report.activityHeader.right",
                      "Date Run: "
                    )}
                  </BoldSubText>
                  <BoldFont data-testid={"SettingsAssessedTime" + i}>
                    {getDateAndTimeFormat4(settings[0].assessedTime, locale)}
                  </BoldFont>
                </HData>
              </THead>
              <tbody>
                {settings.map((setting, i) => {
                  return (
                    <>
                      <tr>
                        <SettingNameHeading>
                          <RiskColor risk={setting.complianceResult} />
                          <SettingName>
                            {t(
                              `ecp-endpointsecurity-devicespage.report.settings.${setting.settingId}.name`,
                              ""
                            )}
                          </SettingName>
                        </SettingNameHeading>
                        {!showSubSetting[setting.settingId] ? (
                          displayNoSubSetting(setting)
                        ) : (
                          <>
                            <td></td>
                            <td></td>
                          </>
                        )}
                      </tr>
                      {displaySubSetting(setting)}
                      <tr>
                        <td style={{ paddingBottom: "10px" }}></td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </Table>
          );
        })
      ) : (
        <Table key={"1"}>
          <THead>
            <HData>-</HData>

            <HData
              style={{
                textAlign: "right",
                paddingRight: "12px",
                float: "right"
              }}
            >
              <BoldSubText>
                {t(
                  "ecp-endpointsecurity-devicespage.report.activityHeader.right",
                  "Date Run: "
                )}
              </BoldSubText>
              <BoldFont data-testid={"SettingsAssessedTimeNUll"}>-</BoldFont>
            </HData>
          </THead>
          <tbody>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                margin: "5% 150%"
              }}
            >
              <span style={{ alignSelf: "center" }}>
                <IconWarningAlt />
              </span>

              <p
                style={{
                  alignSelf: "center",
                  whiteSpace: "nowrap"
                }}
              >
                {Tablei18nText(t).noItems}
              </p>
            </div>
          </tbody>
        </Table>
      )}
    </PDFContainer>
  );
};

export default ReportDetailsHTML;
