import { Accordion } from "@veneer/core";
import { AccordionItemInterface } from "@veneer/core";
import React, { useState } from "react";
import { useI18n } from "@jarvis/react-portal-addons";
import PropTypes from "prop-types";
import AccordionContentRemediation from "../AccordianContentRemediation";
import { getDateAndTimeFormat4 } from "../../utils/commonMethods";

const ReportAccordionRemediation = ({
  remdiationDetails,
  selectReportTime,
  locale,
  isWex
}) => {
  const { t } = useI18n();
  const accordionList =
    remdiationDetails && remdiationDetails.length > 0
      ? remdiationDetails.map((remediatelist, index) => {
          const arrayOfRemediatelist = Object.values(remediatelist);
          const item: AccordionItemInterface = {};
          const category = arrayOfRemediatelist[0][0]?.category
            ? arrayOfRemediatelist[0][0].category
            : "default";
          item.id = "device-report-policyname-" + index;
          item.header = {
            startArea: (
              <span data-testid={"device-report-policyname-" + index}>
                {t(
                  `ecp-endpointsecurity-devicespage.report.activityHeader.left.${category}.part1`,
                  ""
                )}
                {t(
                  `ecp-endpointsecurity-devicespage.report.activityHeader.left.${category}.part2`,
                  ""
                )}
              </span>
            ),
            centralArea: <span></span>,
            endArea: (
              <span data-testid={"device-report-policydaterun-" + index}>
                {t(
                  `ecp-endpointsecurity-devicespage.report.activityHeader.right`,
                  "Date Run: "
                )}
                {getDateAndTimeFormat4(
                  arrayOfRemediatelist[0][0]?.assessedTime,
                  locale
                )}
              </span>
            )
          };
          item.expanded = true;
          item.content = (
            <AccordionContentRemediation
              isWex = {isWex}
              remediationSettings={arrayOfRemediatelist}
            />
          );

          return item;
        })
      : [];

  const [data, setData] = useState<AccordionItemInterface[]>(accordionList);
  const handleExpand = (event, index, item) => {
    const updatedData = [...data];
    updatedData[index].expanded = true;
    setData(updatedData);
  };

  const handleCollapse = (event, index, item) => {
    const updatedData = [...data];
    updatedData[index].expanded = false;
    setData(updatedData);
  };

  return (
    <div>
      {remdiationDetails && remdiationDetails.length > 0 ? (
        <Accordion
          gutter={5}
          appearance="outlined"
          items={data}
          onExpand={handleExpand}
          onCollapse={handleCollapse}
          id="RemediationAccordionReport"
        />
      ) : (
        <div style={{ padding: "7% 0% 7% 38%", fontSize: "19px" }}>
          {t(
            `ecp-endpointsecurity-devicespage.report.placeholder.noDataToShow`,
            "No data to show"
          )}
        </div>
      )}
    </div>
  );
};
export default ReportAccordionRemediation;

ReportAccordionRemediation.propTypes = {
  remdiationDetails: PropTypes.array.isRequired,
  selectReportTime: PropTypes.any.isRequired
};
