import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { MicroFrontend } from "@jarvis/react-mfe-component";
import { RootProvider } from "@jarvis/react-portal-addons";
import resources from "../../../assets/locale/index";
import { DeviceDetails } from "../../DeviceDetails";
import { setProps } from "../../../utils/commonMethods";
import "../../../../src/styles/global.scss";

const SubMfeECPDevices = props => {
  let navigationId = " ";
  const { stack, shell, localization, breadCrumbs } = props;
  const language = localization.language;
  const country = localization.country;
  const customRelativePath = "/solutions/security/devices";
  const baseUrl = "/solutions/security/devices";
  setProps(props);

  // useEffect(() => {
  //   setRefreshFlag && setRefreshFlag(false);
  // }, []);

  const SubMfe = useCallback(
    ({ component, type }) => {
      return (
        <div>
          <MicroFrontend
            {...props}
            {...{
              component,
              type,
              stack,
              shell,
              country,
              language,
              customRelativePath,
              columns: [
                "modelName",
                "assessment",
                "status",
                "connectionState",
                "serialNumber",
                "policies",
                "group"
              ],
              showDevicesTableCheckbox: false,
              showDevicesGroupCUD: false,
              showDevicesMainHeader: false,
              serviceID: "ws-hp.com/smcloud",
              deviceDetailsComponent: (
                <DeviceDetails
                  breadCrumbs={breadCrumbs}
                  baseurl={baseUrl}
                  navigation={props.navigation}
                  stack={props.stack}
                  setDeviceName={props.setDeviceName}
                  accessControl={props.accessControl}
                  locale={localization?.locale}
                />
              )
            }}
            {...navigationId}
          />
        </div>
      );
    },
    [shell, stack, props, navigationId]
  );

  return (
    <div className="SubMfeECPDevices">
      <RootProvider localization={localization} resources={resources}>
        <SubMfe type="ECPDevices" component="@jarvis/react-ecp-devices" />
      </RootProvider>
    </div>
  );
};

SubMfeECPDevices.defaultProps = {
  shell: {},
  stack: null
};

SubMfeECPDevices.propTypes = {
  shell: PropTypes.objectOf(PropTypes.any),
  stack: PropTypes.number
};

export default SubMfeECPDevices;
